<template>
    <!--<meta property="og:url" content="@($" {Context.Request.Scheme}: //{Context.Request.Host}{Context.Request.Path}{Context.Request.QueryString}")" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="The Pet Parent Academy" />
    <meta property="og:description" content="Find out more about being the best pet parent you can be!" />
    <meta property="og:image" content="https://res.cloudinary.com/dqwmahefm/image/upload/v1684843026/pawpedia/static/HPN_HPP_Logo_sp2ail.svg" />-->

    <meta property="og:url" content="/course/{{courseid}}" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="The Pet Parent Academy" />
    <meta property="og:description" content="Find out more about being the best pet parent you can be!" />
    <meta property="og:image" content="https://res.cloudinary.com/dqwmahefm/image/upload/v1684843026/pawpedia/static/HPN_HPP_Logo_sp2ail.svg" />
    <div>Facebook Share {{courseid}}</div>
    {{course.species}}

</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import axios from 'axios'
    import apiUrl from '../scripts/BaseUrl';
  
     const courseId = location.pathname.split('/')[2]

     export default defineComponent({
        name: "Facebook",
        components: {
          
        },
        data() {          
            return {
                loading: false,
                course: '' ,
                courseid: location.pathname.split('/')[2]
            };
        },
        methods: {
      


          
        },
          async mounted() {
              this.loading = true;               
              await axios
              .get(apiUrl + '/courses/' + courseId + '/summarized')
              .then((response) => {
                this.loading = false;
                this.course = response.data;                          
            })
            .catch((error) => {
                this.loading = false;
                return error;

            });

      
          },
    });
</script>


