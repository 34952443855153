<template>
    <div>
        <ul>
            <li v-for="course of courses" :key="course.id">
                <a :href="`/course/${course.id}`">{{course.name}}</a>
            </li>
        </ul>
    </div>   
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import axios from "axios";
    import apiUrl from '../scripts/BaseUrl';
    export default defineComponent({
        name: "Sitemap",
        components: {
        },
        data() {
            return {
                loading: false,
                courses: [] as any
              };
        },       
        async mounted() {
            this.loading = true;
            await axios
            .get(apiUrl + "/courses/summarized")
            .then((response) => {
                this.loading = false;
                this.courses = response.data.items;
                })
            .catch((error) => {
                this.loading = false;
                return error;
            });
        },
    })
</script>

<style>
    #courses {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
</style>
