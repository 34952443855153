<template>
    <div class="wrapper">
        <div class="container-fluid mx-auto pt-4">
            <div class="row">
                <div class="col-12 course-for d-flex justify-content-center pb-2"><p class="text-blue mb-0 text-center">Search Filter:</p></div>
            </div>
            <div class="row mb-3">
                <div class="col-12 course-for">
                    <div class="filter-wrapper d-lg-flex d-md-flex justify-content-center">
                        <div>
                            <!--<p class="text-blue mb-0 d-inline-block w-63">Filter:</p>-->
                            <div class="form-check form-check-inline">
                                <input type="checkbox" class="btn-check" id="checkbox-dog" value="canine" @change="onSpeciesChange" :checked="lessonsFilterStore.species === 'canine'">
                                <label class="btn btn-light white btn-sm rounded-0 text-uppercase bold-text" for="checkbox-dog">
                                    <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675265143/pawpedia/static/dog-transparent_gaoxsa.png" class="species" />
                                    Dog
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="checkbox" class="btn-check" id="checkbox-cat" value="feline" @change="onSpeciesChange" :checked="lessonsFilterStore.species === 'feline'">
                                <label class="btn btn-light white btn-sm rounded-0 text-uppercase bold-text" for="checkbox-cat">
                                    <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675265143/pawpedia/static/cat-transparent_k0m7eq.png" class="species" />
                                    Cat
                                </label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn border-0 btn-sm p-0 text-blue bold-text filter" v-on:click="clearFilterSpecies()"><u>Clear Filter</u></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 course-for">
                    <div class="filter-wrapper d-lg-flex d-md-flex justify-content-center">
                        <div>
                            <!--<p class="text-blue mb-0 d-inline-block w-63">Filter:</p>-->
                            <div class="form-check form-check-inline">
                                <select @change="onFileTypeChange" class="form-select rounded-0  bg-light custom" aria-label="filetype" :value="lessonsFilterStore.filetype">
                                    <option selected value="">MEDIA TYPE</option>
                                    <option value="Video">Video</option>
                                    <option value="Audio">Audio</option>
                                    <option value="Pdf">PDF</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn border-0 btn-sm p-0 text-blue bold-text filter" v-on:click="clearFilterType()"><u>Clear Filter</u></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 course-for">
                    <div class="filter-wrapper d-lg-flex d-md-flex justify-content-center">
                        <div>
                            <!--<p class="text-blue mb-0 d-inline-block w-63">Search:</p>-->
                            <div class="form-check form-check-inline">
                                <div class="searchbar">
                                    <input type="text" placeholder="Search Text" v-model="searchValue" @keyup.enter.native="searchChange">
                                    <div class="input-group-append">
                                        <button class="btn btn-secondary rounded-0" type="button" @click="searchChange">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn border-0 btn-sm p-0 text-blue bold-text filter" v-on:click="clearFilterSearch()"><u>Clear Filter</u></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container vh-50 d-flex align-items-center justify-content-center" v-if="loading">
                <div class="d-flex flex-column text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                </div>
            </div>
            <div v-if="!loading">
                <div v-if="courses.length > 0">
                     <div class="course wrapper px-0" v-for="course of courses" :key="course.id">
                        <div class="course wrapper-sml">  
                            <div class="course full-width px-0">
                                <div class="course-wrapper full-width parent pb-4">                          
                                    <div class="content ps-0">
                                        <div v-if="loadingModules" class="text-center vh-50 p-5 m-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                                        </div>
                                        <div class="item" v-for="(theme, i) in course.themes" :key="theme.id">
                                            <div class="module-wrapper nopadding" v-for="(module , i) in theme.modules" :key="module.id">
                                                <div v-if="module.lessons.length > 0">
                                                     <div class="filters full-width">                                                     
                                                        <div class="filter-button full-width p-lg-4 p-md-4 p-sm-3 active">
                                                            <span class="my-highlight d-flex align-content-center" style="line-height:1.5">  
                                                                <span>
                                                                    <span v-if="course.species === 'Feline'">
                                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1722249667/pawpedia/static/cat-transparent-white_uiah6z.png" class="lessonIcon pe-2" />
                                                                    </span>
                                                                    <span v-if="course.species === 'Canine'">
                                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1722249668/pawpedia/static/dog-transparent-white_jlkxr0.png" class="lessonIcon pe-2" />
                                                                    </span>
                                                                </span>
                                                                <Highlighter class="my-highlight"
                                                                             highlightClassName="highlight"
                                                                             :searchWords="keywords"
                                                                             :autoEscape="true"
                                                                             :textToHighlight="module.name" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="module.name">     
                                                        <div v-if="module.description">
                                                            <div class="V-accordion_trigger ps-3 pe-3">
                                                                <p class="full-width" v-if="module.description">
                                                                    <Highlighter class="my-highlight"
                                                                                 highlightClassName="highlight"
                                                                                 :searchWords="keywords"
                                                                                 :autoEscape="true"
                                                                                 :textToHighlight="module.description" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="V-accordion_body sml">
                                                            <div class="lessons d-flex align-content-between ps-3 pe-3" v-for="(lesson, i) in module.lessons" :key="lesson.id" :id="lesson.id">
                                                                <div class="lesson-left" @click="openModal(course.id, theme.id, module.id, lesson.id)">
                                                                    <h5>
                                                                        <Highlighter class="my-highlight"
                                                                                     highlightClassName="highlight"
                                                                                     :searchWords="keywords"
                                                                                     :autoEscape="true"
                                                                                     :textToHighlight="lesson.name" />
                                                                    </h5>
                                                                    <div class="d-flex align-items-center">
                                                                        <div v-for="(content, i) in lesson.lessonContent.slice(0,1)" :key="content.id">
                                                                            <div v-if="content.fileType == 'Audio'">
                                                                                <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/microphone_djfrek.svg" alt="audio" />
                                                                            </div>
                                                                            <div v-if="content.fileType == 'Pdf'">
                                                                                <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/document_eoybse.svg" alt="pdf" />
                                                                            </div>
                                                                            <div v-if="content.fileType == 'Video'">
                                                                                <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/video_hzxeqf.svg" alt="video" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="pe-3">
                                                                            <h6 v-for="(content, i) in lesson.lessonContent.slice(0,1)" :key="content.id">
                                                                                <span v-show="content.fileType == 'Audio'">{{lesson.duration}} minute listen</span>
                                                                                <span v-show="content.fileType == 'Pdf'">{{lesson.duration}} minute read</span>
                                                                                <span v-show="content.fileType == 'Video'">{{lesson.duration}} minute video</span>
                                                                            </h6>
                                                                            <div class="copy">
                                                                                <Highlighter class="my-highlight"
                                                                                             highlightClassName="highlight"
                                                                                             :searchWords="keywords"
                                                                                             :autoEscape="true"
                                                                                             :textToHighlight="lesson.description" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button class="red text-uppercase" @click="openModal(course.id, theme.id, module.id, lesson.id)">Let's Go</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pb-5 pr-5 d-flex justify-content-end" v-if="!searchValue">
                        <Pagination :skip="lessonsFilterStore.skip" :take="lessonsFilterStore.take" :skipped="lessonsFilterStore.result.skipped" :taken="lessonsFilterStore.result.taken" :total="lessonsFilterStore.result.total" @prevPage="lessonsFilterStore.setPrevPage()" @nextPage="lessonsFilterStore.setNextPage()" />
                    </div>
                </div>
                <div v-if="courses.length === 0 || lessonsFilterStore.result.total === 0">
                    <div class="container vh-50 d-flex align-items-center justify-content-center">
                        <div class="d-flex flex-column text-center p-4">
                            <h3 class="h5 text align-self-center">
                                No results to display
                            </h3>
                        </div>
                    </div>
                </div>
                <div v-for="course of courses.slice(0,1)" :key="course.id">
                    <div v-if="course.themes.slice(0,1).length === 0">
                        <div class="container vh-50 d-flex align-items-center justify-content-center  p-4">
                            <div class="d-flex flex-column text-center p-4">
                                <h3 class="h5 text align-self-center">
                                    No results to display
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="courses.length === 0">
                    <div class="container vh-50 d-flex align-items-center justify-content-center" v-for="course of courses" :key="course.id">
                        <div class="d-flex flex-column text-center p-4">
                            <h3 class="h5 text align-self-center">
                                No results to display
                            </h3>
                        </div>
                    </div>
                </div>
                <div v-for="course of courses.slice(0,1)" :key="course.id">
                    <div v-if="course.themes.slice(0,1).length === 0">
                        <div class="container vh-50 d-flex align-items-center justify-content-center  p-4">
                            <div class="d-flex flex-column text-center p-4">
                                <h3 class="h5 text align-self-center">
                                    No results to display
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <GDialog v-model="dialogState">
                    <div class="form-wrapper">
                        <div class="container">
                            <Form class="no-lr-padding" @submit="handleLogin(courseId, themeId, moduleId, lessonId)" :validation-schema="schema" v-slot="{ errors }">
                                <div class="details">
                                    <button class="close"
                                            @click.prevent="dialogState = false">
                                        x
                                    </button>
                                    <h2>REGISTER OR LOGIN TO CONTINUE. <br />IT'S FREE!</h2>
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <Field type="email" v-model="model.userName" id="name" name="username" class="form-control rounded-0" :class="{ 'is-invalid': errors.username }" />
                                                <label for="name">Email Address *</label>
                                                <div class="invalid-feedback">{{errors.username}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <Field type="password" v-model="model.password" id="password" name="password" class="form-control rounded-0" :class="{ 'is-invalid': errors.password }" />
                                                <label for="name">Password *</label>
                                                <div class="invalid-feedback">{{errors.password}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons">
                                    <div class="dmessage">
                                        <div class="redColour" v-if="dmessage">
                                            {{ dmessage }}
                                        </div>
                                    </div>
                                    <div>

                                        <button class="btn btn-primary rounded-0 px-4" :disabled="loginloading">
                                            <span v-show="loginloading" class="spinner-border spinner-border-sm"></span>
                                            <span>Login</span>
                                        </button>
                                    </div>
                                    <div class="divider"><span>OR</span></div>
                                    <div><a href="/register" class="btn btn-outline-primary rounded-0">Register</a></div>
                                    <div><a href="/forgot-password" class="text-medium">Forgot password?</a></div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </GDialog>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import axios from "axios";
    import apiUrl from '../scripts/BaseUrl';

    import { GDialog } from 'gitart-vue-dialog'
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';
    import Highlighter from 'vue-highlight-words';

    import Pagination from '../../components/Pagination.vue';

    //store
    import { mapStores } from 'pinia';
    import { lessonsFilterStore } from './store/lessons-store';
    import type { lessonFilterModel } from './store/filter-model-lessons';
    let searchTimer: any;

    export default defineComponent({
        name: 'OurLessons',
        components: {
            GDialog,
            Form,
            Field,
            axios,
            Pagination,
            Highlighter,

        },
        data() {
            const schema = yup.object().shape({
                username: yup.string().required('Email is required').email('Email is invalid'),
                password: yup.string().required("Password is required!"),
            });
            return {
                schema,
                loading: false,
                courses: [] as any,
                modules: [] as any,
                lessons: [] as any,
                searchValue: '',
                courseId: '',
                themeId: '',
                moduleId: '',
                enrolId: '',
                lessonId: '',
                dialogState: false,
                userLogged: false,
                loginloading: false,
                dmessage: '',
                message: '',
                model: {
                    userName: '',
                    password: '',
                },
                words: '',
                firstIndex: 0,
                selected: '',
                selectedCourse: '',
                show: true,      
            }
        },
        methods: {
            openModal(courseId: any, themeId: any, moduleId: any, lessonId: any) {
                this.courseId = courseId;
                this.moduleId = moduleId;
                this.themeId = themeId;
                this.lessonId = lessonId;
                const courseObjectSchool = {
                    enrolId: this.enrolId,
                    courseId: courseId,
                    moduleId: moduleId,
                    themeId: themeId,
                    lessonId: lessonId,
                }
                if (localStorage.getItem("user")) {       
                    window.location.href = '/school#/lesson/' + lessonId;
                } else {
                    this.dialogState = true;
                }
            },
            handleLogin(courseId: any, themeId: any, moduleId: any, lessonId: any) {
                console.log('login', courseId)
                this.loading = true;
                const authData = {
                    auth:
                    {
                        username: this.model.userName,
                        password: this.model.password
                    }
                }
                const authToken = window.btoa(this.model.userName + ':' + this.model.password);
                return axios.post(apiUrl + '/accounts', this.model, authData)
                    .then((response) => {
                        let user = response.data;
                        user.token = authToken;
                        localStorage.setItem('user', JSON.stringify(user));
                        if (user.deactivatedAt) {
                            this.dialogState = true;
                            this.dmessage = 'User have been Deactivated';
                        } else {
                            this.dialogState = false;
                            this.loading = true;                          
                            window.location.href = '/school#/lesson/' + lessonId;
                        }

                    }, (error) => {
                        this.loading = false;
                        this.dialogState = true;
                        if (error.response) {
                            if (error.response.data) {
                                this.dmessage = error.response.data;
                            } else {
                                this.dmessage = 'An error occured. contact your system administrator';
                            }

                        } else {
                            this.message = error.response.data.message;
                        }
                    });
            },
            async loadLessons(filter?: lessonFilterModel) {
                this.loading = true;
                const response = await axios.get(apiUrl + '/courses/search', { params: filter });
                this.courses = response.data.items;



                this.loading = false;
                const { skipped, taken, total } = response.data;
                this.lessonsFilterStore.setResult(total, skipped, taken);
            },
            clearFilter() {
                this.lessonsFilterStore.$reset();
                this.searchValue = '';
            },
            clearFilterSpecies() {
                this.lessonsFilterStore.setSpecies('');
                const x = window.matchMedia("(max-width: 991px)")
                if (x.matches) {
                    this.firstIndex = 0;
                }
                return;
            },
            clearFilterType() {
                this.lessonsFilterStore.setFileType('');
                const x = window.matchMedia("(max-width: 991px)")
                if (x.matches) {
                    this.firstIndex = 0;
                }
                return;  
            },
            clearFilterSearch() {  
                this.searchValue = '';
                    clearTimeout(searchTimer);
                    searchTimer = setTimeout(() => {
                        this.lessonsFilterStore.setSearch('');
                    }, 50);
                const x = window.matchMedia("(max-width: 991px)")
                if (x.matches) {
                    this.firstIndex = 0;
                }
            },
            onSpeciesChange(event: any) {
                if (this.lessonsFilterStore.species === event.target.value) {
                    this.lessonsFilterStore.setSpecies('');
                    return;
                }
                this.lessonsFilterStore.setSpecies(event.target.value);
            },
            onFileTypeChange(event: any) {
                this.lessonsFilterStore.setFileType(event.target.value);
            },
            onTakeChange(event: any) {
                this.lessonsFilterStore.setTake(event.target.value ? Number(event.target.value) : 10);
            },
            searchChange() {             
                clearTimeout(searchTimer);
                searchTimer = setTimeout(() => {
                    this.lessonsFilterStore.setSearch(this.searchValue);
                }, 1000);
            },
            makeActive(themeId: any, courseId: any) {
                this.selected = themeId;
                this.firstIndex = themeId;
                this.show = false;  
                this.selectedCourse = courseId;               
            }, 
            checkUserExists() {
                if (localStorage.getItem("user")) {
                    window.location.href = '/school#/lessons';
                }
            },
        },
        async mounted() {
            this.clearFilter();
            this.loading = true;
            this.loadLessons(this.lessonsFilterStore.$state);
            this.lessonsFilterStore.$subscribe((mutation, state) => {
                if (!state.isUpdate) {
                    return;
                }
                this.loadLessons(state);
            });
            const x = window.matchMedia("(max-width: 991px)")
            if (x.matches) {
                this.firstIndex = 0;
            }
        },
        created() {
            this.checkUserExists();
        },
        computed: {
            ...mapStores(lessonsFilterStore('lessonsFilter')),
            keywords() {
                if (typeof this.searchValue === 'string') {
                    this.words = (this.searchValue!).toString();
                    return this.words.split(' ');
                }
            },
        }
    })
</script>
<style>
    #courses {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
</style>
