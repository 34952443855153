﻿import { defineStore } from "pinia";
import { cloneDeep } from 'lodash';
import type { lessonFilterModel } from "./filter-model-lessons";

export interface Filter extends lessonFilterModel {
    isUpdate: boolean;
}

const initialState: Filter = {
    letter: '',
    status: '',
    species: '',
    type: '',
    filetype: '',
    unrgistered: '',
    loggedIn: '',

    page: 0,
    skip: 0,
    take: 3,
    orderBy: 'updatedAt',
    orderDirection: 'asc',
    search: '',
    includeDeleted: false,
    result: {
        total: 0,
        skipped: 0,
        taken: 0,
    },
    isUpdate: true,
}

export function lessonsFilterStore<Id extends string>(id: Id) {
    return defineStore({
        id,
        state: () => cloneDeep(initialState),        
        actions: {
            // custom
            setLetter(letter: string) {
                this.letter = letter;
                this.setPage(0);
                this.isUpdate = true;
            },
            setStatus(status: string) {
                this.status = status;
                this.setPage(0);
                this.isUpdate = true;
            },
            setType(type: string) {
                this.type = type;
                this.setPage(0);
                this.isUpdate = true;
            },
            setFileType(filetype: string) {
                this.filetype = filetype;
                this.setPage(0);
                this.isUpdate = true;
            },
            setSpecies(species: string) {
                this.species = species;
                this.setPage(0);
                this.isUpdate = true;
            },
            setLoggedIn(loggedIn: boolean | string) {
                this.unrgistered = loggedIn ? '' : this.unrgistered;
                this.loggedIn = loggedIn;
                this.setPage(0);
                this.isUpdate = true;
            },
            setUnregistered(unrgistered: boolean | string) {
                this.loggedIn = unrgistered ? '' : this.loggedIn;
                this.unrgistered = unrgistered;
                this.setPage(0);
                this.isUpdate = true;
            },

            setPage(page: number) {
                this.page = page;
                this.skip = page * this.take;
                this.isUpdate = true;
            },
            setTake(take: number) {
                this.take = take;
                this.isUpdate = true;
            },
            setOrderBy(orderBy: string) {
                this.setPage(0);
                this.orderBy = orderBy;
                this.orderDirection = 'asc';
                this.isUpdate = true;
            },
            setOrderDirection(orderDirection: string) {
                this.setPage(0);
                this.orderDirection = orderDirection;
                this.isUpdate = true;
            },
            setSearch(search: string) {
                this.search = search;
                this.setPage(0);
                this.isUpdate = true;
            },
            setIncludeDeleted(includeDeleted: boolean) {
                this.includeDeleted = includeDeleted;
                this.isUpdate = true;
            },
            setResult(total: number, skipped: number, taken: number) {
                this.result.taken = taken;
                this.result.skipped = skipped;
                this.result.total = total;
                this.isUpdate = false;
            },
            setNextPage() {
                if ((this.page + 1) * this.take > this.result.total) {
                    return;
                }
                this.setPage(this.page + 1);
            },
            setPrevPage() {
                if ((this.page - 1) < 0) {
                    return;
                }
                this.setPage(this.page - 1);
            }
        }
    });
}
