﻿<template>
    <div class="d-flex align-items-center">
        <div v-if="authStore.isLoggedIn">
            <div class="profile-wrapper-nav desktop">
                <div class="stats" v-if="authStore.loading">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="30" width="30" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                </div>
                <div else>
                    <button class="profile">
                        <span>{{authStore.fullName}}</span>
                        <span v-if="authStore.avatar">
                            <img :src="authStore.avatar" :alt="authStore.fullName" class="avatar" v-if="!authStore.loading">
                        </span>
                        <span v-if="!authStore.avatar">
                            <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1669066755/pawpedia/static/avatar_kjq5e9.png" alt="Profile" class="avatar" @error="imageLoadOnError">
                        </span>
                    </button>
                </div>
                <div class="info">
                    <div class="settings">
                        <!--<router-link to="/profile-settings" class="ms-2" style="font-size: 0.75rem">Profile Settings</router-link>-->
                        <a href="/school#/profile-settings">Profile Settings</a>
                        <a v-if="authStore.isAdmin" href="/admin#/courses">Admin Dashboard</a>
                        <a href="/school#/notifications">Notifications</a>
                        <button v-on:click="authStore.logout()">Log Out</button>
                    </div>
                </div>
            </div>
            <!--<div class="profile-wrapper-nav mobile">
            <router-link v-if="!authStore" to="/profile-settings" class="ms-2" style="font-size: 0.75rem">Profile Settings</router-link>
            <a v-if="!authStore.isAdmin" href="/school">SCHOOL</a>
            <div v-if="!authStore.isAdmin" v-on:click="authStore.logout()">LOG OUT</div>
        </div>-->
        </div>
        <div v-else>
            <div class="navbar-nav right-nav">
                <div class="nav-item">
                    <a class="btn btn-outline-primary bold-text rounded-0 notregistered" href="/register">Not registered yet?</a>
                </div>
                <div>
                    <a href="/login" class="btn btn-outline-primary bold-text rounded-0">Login</a>
                </div>
            </div>
        </div>
        <div class="position-relative">
            <ul class="nav">
                <li class="nav-item bold-text search">
                    <button type="button" class="swap desktop position-absolute">
                        <i class="fas fa-search iconImg"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useAuthStore } from '../stores/auth/auth-store';
    const authStore = useAuthStore();
</script>