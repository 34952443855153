﻿import $ from "jquery";
import { createPinia } from "pinia";
import VueGtag from "vue-gtag";
/*import VueGtag from "vue-gtag-next";*/

import scrollUp, { scrollBtn } from './scripts/backtotop.js';
scrollBtn?.addEventListener("click", scrollUp);
import navMenu from "./scripts/menu";
navMenu();
import readMore from "./scripts/readmore.js";
readMore();

import videoPlayer from "./scripts/video.js";
videoPlayer();

import { createApp } from 'vue';

import Modal from './vue/Modal.vue';
createApp(Modal).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#login");


import Faqs from './vue/Faqs.vue';
createApp(Faqs).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).use(createPinia()).mount("#faqs");


import Glossary from './vue/Glossary.vue';
createApp(Glossary).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).use(createPinia()).mount("#glossary");


import Contact from './vue/Contact.vue';
createApp(Contact).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#contact");

import Register from './vue/Register.vue';
createApp(Register).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#register");

import Login from './vue/Login.vue';
createApp(Login).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#login");


import ForgotPassword from './vue/ForgotPassword.vue'
createApp(ForgotPassword).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#forgot");


import ResetPassword from './vue/ResetPassword.vue';
createApp(ResetPassword).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#reset-pass");


import CoachesSlider from './vue/CoachesSlider.vue';
createApp(CoachesSlider).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#coaches-slider");


import Coaches from './vue/Coaches.vue';
createApp(Coaches).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#coaches");


import CoursesSlider from './vue/CoursesSlider.vue';
createApp(CoursesSlider).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#courses-slider");


import Courses from './vue/Courses.vue';
createApp(Courses).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#courses");


import Course from './vue/Course.vue';
createApp(Course).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#course");


import Module from './vue/Module.vue';
createApp(Module).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#module");


import Footer from './vue/Footer.vue'
createApp(Footer).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#footer-courses");


import Sitemap from './vue/Sitemap.vue'
createApp(Sitemap).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#sitemapCourses");

import AuthMenu from '../components/AuthMenu.vue';
createApp(AuthMenu).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).use(createPinia()).mount("#authMenu");

import FacebookShare from './vue/FacebookShare.vue'
createApp(FacebookShare).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#facebook");

import SearchResults from './vue/SearchResults.vue'
createApp(SearchResults).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#searchResults");

import OurLessons from './vue/Lessons.vue'
createApp(OurLessons).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#ourLessons");

import lesson from './vue/Lesson.vue'
createApp(lesson).use(VueGtag, {
    appName: 'Public Not loggedin',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-CCPHLRT7QD',
        params: {
            anonymize_ip: true
        }
    }
}).mount("#lesson");


//import SiteEnv from './vue/SiteEnv.vue'
//createApp(SiteEnv).mount("#site");

//import Glossary from './vue/Glossary.vue';
//createApp(Glossary).use(createPinia()).mount('#glossary');



