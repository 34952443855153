<template>
    <div class="wrapper">
        <div class="container mx-auto px-sm-0 px-md-0">
            <div class="row mt-md-5 mt-sm-3  mb-3">
                <div class="col-12 course-for">
                    <div class="filter-wrapper d-lg-flex d-md-flex justify-content-center">
                        <div>
                            <!--<p class="text-blue mb-0 d-inline-block">Filter:</p>-->
                            <div class="form-check form-check-inline">
                                <input type="checkbox" class="btn-check" id="checkbox-dog" value="canine" @change="onSpeciesChange" :checked="lessonsFilterStore.species === 'canine'">
                                <label class="btn btn-light white btn-sm rounded-0 text-uppercase bold-text custom" for="checkbox-dog">
                                    <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675265143/pawpedia/static/dog-transparent_gaoxsa.png" class="species" />
                                    Dog
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="checkbox" class="btn-check custom" id="checkbox-cat" value="feline" @change="onSpeciesChange" :checked="lessonsFilterStore.species === 'feline'">
                                <label class="btn btn-light white btn-sm rounded-0 text-uppercase bold-text" for="checkbox-cat">
                                    <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675265143/pawpedia/static/cat-transparent_k0m7eq.png" class="species" />
                                    Cat
                                </label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn border-0 btn-sm p-0 text-blue bold-text filter" v-on:click="clearFilterSpecies()"><u>Clear Filter</u></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 course-for">
                    <div class="filter-wrapper d-lg-flex d-md-flex justify-content-center">
                        <div>
                            <!--<p class="text-blue mb-0 d-inline-block">Filter:</p>-->
                            <div class="form-check form-check-inline">
                                <select @change="onTypeChange" class="form-select rounded-0 bg-light custom" aria-label="Type" :value="lessonsFilterStore.type">
                                    <option selected value="">TYPE</option>
                                    <option value="Expert">Expert</option>
                                    <option value="LifeStage">Lifestage</option>
                                    <option value="PuppySchool">Puppy School</option>
                                    <option value="QuickByte">Quick Byte</option>
                                    <option value="PrePet">Pre-pet</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn border-0 btn-sm p-0 text-blue bold-text filter" v-on:click="clearFilterType()"><u>Clear Filter</u></button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading">
                <div class="container vh-50 d-flex align-items-center justify-content-center">
                    <div class="p-xl-5 p-md-3">
                        <div class="d-flex flex-column text-center pt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!loading">
                <div v-if="courses.length > 0">
                    <h2 class="h5 pt-md-5 pt-sm-5 pb-3 fw-bold courses">
                        SEARCH RESULTS FOR <span class="text-uppercase">"{{searchFilter}}"</span>
                    </h2>
                    <div class="search-results pb-0">
                        <div class="list">
                            <div class="courses">
                                <div class="bg-white mb-4" v-for="course of courses" :key="course.id">
                                    <div class="text-decoration-none">
                                        <div class="courses-wrapper">
                                            <div class="d-flex pt-4" v-bind:class="(course.themes.length > 0)?'':'pb-4'">
                                                <div class="d-flex">
                                                    <div class="position-relative mobileNone">
                                                        <div class="circular-img adjust">
                                                            <picture>
                                                                <source media="(max-width:480px)" :srcset="course.desktopMediaAsset.cdnUri" @error="imageLoadOnError">
                                                                <img :src="course.desktopMediaAsset.cdnUri" :alt="course.name" @error="imageLoadOnError">
                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <div class="ms-3" v-bind:class="(course.themes.length > 0)?'':'noThemes'">
                                                        <div class="card rounded-0 border-0">
                                                            <div class="card-body d-flex flex-column cursonPointer cursor-pointer" @click="goToCourse(course.id)">
                                                                <p class="card-text pb-0 mb-0 pe-3 text-left bg-white">
                                                                    <strong class="fw-bolder h2 text-uppercase">
                                                                        <Highlighter class="my-highlight"
                                                                                     highlightClassName="highlight"
                                                                                     :searchWords="keywords"
                                                                                     :autoEscape="true"
                                                                                     :textToHighlight="course.name" /> ::
                                                                    </strong>
                                                                    <Highlighter class="my-highlight"
                                                                                 highlightClassName="highlight"
                                                                                 :searchWords="keywords"
                                                                                 :autoEscape="true"
                                                                                 :textToHighlight="course.description" /> :
                                                                    <button class="readmore-link border-0 p-0 ms-2">Read More</button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="pb-4">
                                                            <div>
                                                                <div v-for="theme of course.themes" :key="theme.id">
                                                                    <div v-for="module of theme.modules" :key="module.id">
                                                                        <div class="module cursonPointer cursor-pointer" @click="goToTheme(course.id,theme.id)">
                                                                            <span class="text-uppercase h3">
                                                                                <Highlighter class="my-highlight"
                                                                                             highlightClassName="highlight"
                                                                                             :searchWords="keywords"
                                                                                             :autoEscape="true"
                                                                                             :textToHighlight="module.name" /> :
                                                                            </span>
                                                                            <span class="par" v-if="module.description">
                                                                                -
                                                                                <Highlighter class="my-highlight"
                                                                                             highlightClassName="highlight"
                                                                                             :searchWords="keywords"
                                                                                             :autoEscape="true"
                                                                                             :textToHighlight="module.description" /> :
                                                                            </span>
                                                                            <button class="readmore-link border-0 p-0 ms-2">Read More</button>
                                                                        </div>
                                                                        <div class="module" v-for="lesson of module.lessons" :key="lesson.id">
                                                                            <div class="lessons d-flex align-content-between" v-for="content of lesson.lessonContent.slice(0,1)" :key="content.id">
                                                                                <div class="lesson-left d-flex align-items-center">
                                                                                    <div class="img-box">
                                                                                        <div v-if="content.fileType == 'Audio'">
                                                                                            <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/microphone_djfrek.svg" alt="audio" />
                                                                                        </div>
                                                                                        <div v-if="content.fileType == 'Pdf'">
                                                                                            <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/document_eoybse.svg" alt="pdf" />
                                                                                        </div>
                                                                                        <div v-if="content.fileType == 'Video'">
                                                                                            <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/video_hzxeqf.svg" alt="video" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="lessons-box text-decoration-none cursonPointer cursor-pointer" @click="openModalLesson(course.id, theme.id, module.id, lesson.id)" v-if="!userLogged">
                                                                                        <h4>
                                                                                            <Highlighter class="my-highlight"
                                                                                                         highlightClassName="highlight"
                                                                                                         :searchWords="keywords"
                                                                                                         :autoEscape="true"
                                                                                                         :textToHighlight="lesson.name" />
                                                                                        </h4>
                                                                                        <div class="par">
                                                                                            <Highlighter class="my-highlight"
                                                                                                         highlightClassName="highlight"
                                                                                                         :searchWords="keywords"
                                                                                                         :autoEscape="true"
                                                                                                         :textToHighlight="lesson.description" />
                                                                                        </div>
                                                                                        <button class="readmore-link border-0 p-0">Read More</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pb-5 d-flex justify-content-end">
                                    <Pagination :skip="lessonsFilterStore.skip" :take="lessonsFilterStore.take" :skipped="lessonsFilterStore.result.skipped" :taken="lessonsFilterStore.result.taken" :total="lessonsFilterStore.result.total" @prevPage="lessonsFilterStore.setPrevPage()" @nextPage="lessonsFilterStore.setNextPage()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="courses.length === 0 && !loading">
                    <div class="container vh-50 d-flex align-items-center justify-content-center">
                        <div class="d-flex flex-column text-center">
                            <h2 class="h5 pb-3 fw-bold">
                                SEARCH RESULTS FOR <span class="text-uppercase">{{searchFilter}}</span>
                            </h2>
                            <h3 class="h5 text align-self-center">
                                No results to display
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <GDialog v-model="dialogState">
                <div class="form-wrapper">
                    <div class="container">
                        <Form class="no-lr-padding" @submit="handleLogin(courseId, themeId, moduleId, lessonId)" :validation-schema="schema" v-slot="{ errors }">
                            <div class="details">
                                <button class="close"
                                        @click.prevent="dialogState = false">
                                    x
                                </button>
                                <h2>REGISTER OR LOGIN TO CONTINUE. <br />IT'S FREE!</h2>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <Field type="email" v-model="model.userName" id="name" name="username" class="form-control rounded-0" :class="{ 'is-invalid': errors.username }" />
                                            <label for="name">Email Address *</label>
                                            <div class="invalid-feedback">{{errors.username}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <Field type="password" v-model="model.password" id="password" name="password" class="form-control rounded-0" :class="{ 'is-invalid': errors.password }" />
                                            <label for="name">Password *</label>
                                            <div class="invalid-feedback">{{errors.password}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="buttons">
                                <div class="dmessage">
                                    <div class="redColour" v-if="dmessage">
                                        {{ dmessage }}
                                    </div>
                                </div>
                                <div>

                                    <button class="btn btn-primary rounded-0 px-4" :disabled="loginloading">
                                        <span v-show="loginloading" class="spinner-border spinner-border-sm"></span>
                                        <span>Login</span>
                                    </button>
                                </div>
                                <div class="divider"><span>OR</span></div>
                                <div><a href="/register" class="btn btn-outline-primary rounded-0">Register</a></div>
                                <div><a href="/forgot-password" class="text-medium">Forgot password?</a></div>
                            </div>
                        </Form>
                    </div>
                </div>
            </GDialog>

        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import axios from "axios";
    import apiUrl from '../scripts/BaseUrl';
    import Highlighter from 'vue-highlight-words';

    import Pagination from '../../components/Pagination.vue';
    import { GDialog } from 'gitart-vue-dialog'
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';

    import { mapStores } from 'pinia';
    import { lessonsFilterStore } from './store/lessons-store';
    import type { lessonFilterModel } from './store/filter-model-lessons';

    let searchTimer: any;
    export default defineComponent({
        name: "SearchResults",
        components: {
            GDialog,
            Form,
            Field,
            axios,
            Highlighter,
            Pagination
        },
        data() {
            const schema = yup.object().shape({
                username: yup.string().required('Email is required').email('Email is invalid'),
                password: yup.string().required("Password is required!"),
            });
            return {
                schema,
                loading: false,
                courses: [] as any,
                modules: [] as any,
                lessons: [] as any,
                searchValue: '',
                courseId: '',
                themeId: '',
                moduleId: '',
                enrolId: '',
                lessonId: '',
                selected: '',
                selectedMobile: '',
                selectedThemeId: '',
                selectedModuleId: '',
                user: '',
                dialogState: false,
                userLogged: false,
                loginloading: false,
                dmessage: '',
                message: '',
                model: {
                    userName: '',
                    password: '',
                },
                words: '',
                searchFilter: '',
            }
        },
        methods: {
            async loadCourses(filter?: lessonFilterModel) {
                const response = await axios.get(apiUrl + '/courses/search?search=' + this.searchFilter, { params: filter });
                this.courses = response.data.items;
                this.loading = false;

                const { skipped, taken, total } = response.data;
                this.lessonsFilterStore.setResult(total, skipped, taken);
            },
            clearFilter() {
                this.lessonsFilterStore.$reset();
                this.searchValue = '';
            },
            clearFilterSpecies() {
                this.lessonsFilterStore.setSpecies('');
       
            },
            clearFilterType() {
                this.lessonsFilterStore.setType('');         
            },
            onSpeciesChange(event: any) {
                if (this.lessonsFilterStore.species === event.target.value) {
                    this.lessonsFilterStore.setSpecies('');
                    return;
                }
                this.lessonsFilterStore.setSpecies(event.target.value);
            },
            onStatusChange(event: any) {
                if (this.lessonsFilterStore.status === event.target.value) {
                    this.lessonsFilterStore.setStatus('');
                    return;
                }
                this.lessonsFilterStore.setStatus(event.target.value);
            },
            onTypeChange(event: any) {
                this.lessonsFilterStore.setType(event.target.value);
            },
            onTakeChange(event: any) {
                this.lessonsFilterStore.setTake(event.target.value ? Number(event.target.value) : 10);
            },
            searchChange(event: any) {
                clearTimeout(searchTimer);
                searchTimer = setTimeout(() => {
                    this.lessonsFilterStore.setSearch(event.target.value);
                }, 500);
            },
            clearCourseSearch(event: any) {
                this.searchValue = '',
                    clearTimeout(searchTimer);

                searchTimer = setTimeout(() => {
                    this.lessonsFilterStore.setSearch(event.target.value);
                }, 50);
            },
            openModalLesson(courseId: any, themeId: any, moduleId: any, lessonId: any) {
                this.courseId = courseId;
                this.moduleId = moduleId;
                this.themeId = themeId;
                this.lessonId = lessonId;
                if (localStorage.getItem("user")) {
                    window.location.href = '/school#/lesson/' + lessonId;
                } else {
                    this.dialogState = true;

                }
            },
            handleLogin(courseId: any, themeId: any, moduleId: any, lessonId: any) {
                this.loading = true;
                const authData = {
                    auth:
                    {
                        username: this.model.userName,
                        password: this.model.password
                    }
                }
                const authToken = window.btoa(this.model.userName + ':' + this.model.password);
                return axios.post(apiUrl + '/accounts', this.model, authData)
                    .then((response) => {
                        let user = response.data;
                        user.token = authToken;
                        localStorage.setItem('user', JSON.stringify(user));
                        if (user.deactivatedAt) {
                            this.dialogState = true;
                            this.dmessage = 'User have been Deactivated';
                        } else {
                            this.dialogState = false;
                            this.loading = true;
                            if (lessonId) {
                                const courseObjectSchool = {
                                    enrolId: this.enrolId,
                                    courseId: courseId,
                                    moduleId: moduleId,
                                    themeId: themeId
                                }
                                window.sessionStorage.setItem("courseObjectSchool", JSON.stringify(courseObjectSchool));
                                window.location.href = '/school#/lesson/' + lessonId;
                            } else {
                                window.location.href = '/school#/course/' + courseId;
                            }

                        }

                    }, (error) => {
                        this.loading = false;
                        this.dialogState = true;
                        if (error.response) {
                            if (error.response.data) {
                                this.dmessage = error.response.data;
                            } else {
                                this.dmessage = 'An error occured. contact your system administrator';
                            }

                        } else {
                            this.message = error.response.data.message;
                        }
                    });
            },
            imageLoadOnError(event: any) {
                event.target.src = "https://res.cloudinary.com/dqwmahefm/image/upload/v1671006754/pawpedia/static/image_not_available_tqbecm.png"
            },
            getParameter() {
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);

                if (urlParams.has('search')) {
                    this.searchFilter = urlParams.get('search')!;
                }
            },
            goToCourse(courseId: any) {
                const courseObjectSchool = {
                    enrolId: '',
                    courseId: courseId,
                    moduleId: '',
                    themeId: '',
                }
                window.sessionStorage.setItem("courseObjectSchool", JSON.stringify(courseObjectSchool));
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                window.location.href = '/course/' + courseId + '?search=' + urlParams.get('search');
            },
            goToTheme(courseId: any, themeId: any) {
                //const courseObjectSchool = {
                //    enrolId: '',
                //    courseId: courseId,
                //    moduleId: '',
                //    themeId: themeId,
                //}
                //window.sessionStorage.setItem("courseObjectSchool", JSON.stringify(courseObjectSchool));
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                window.location.href = '/course/' + courseId + '?search=' + urlParams.get('search');
            },
            loadSearch() {
                let icons = Array.from(document.querySelectorAll('.iconImg'));
                let container = document.getElementById('search-wrapper')!;
                let inputField = (document.getElementById("searchInput") as HTMLInputElement | null)!;
                inputField.value = (this.searchFilter!).toString();
                container.classList.add('active');
            },
        },
        async mounted() {     
            this.loadSearch();
            this.loading = true;
            this.lessonsFilterStore.take = 5;
            this.loadCourses(this.lessonsFilterStore.$state);
            this.lessonsFilterStore.$subscribe((mutation, state) => {
                if (!state.isUpdate) {
                    return;
                }
                this.loadCourses(state);
            });
        },
        created() {
            this.getParameter();
            this.user = JSON.parse(window.localStorage.getItem("user") || '[]');
        },
        computed: {
            keywords() {
                this.words = (this.searchFilter!).toString()
                return this.words.split(' ')
            },
            ...mapStores(lessonsFilterStore('lessonsFilter')),
        }
    })
</script>
<style>
    #courses {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
    #search-wrapper:not(.active) {
        display: block !important;
    }
</style>
