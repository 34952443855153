<template>
    <div class="container mx-auto">
        <div v-if="loading" class="container text-center">
            <div class="p-5 p-5 p-5 p-5 p-5 p-5 vh-50">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
            </div>
        </div>
        <div class="container" else>
            <div class="row pb-5">
                <div class="col-sm-12 col-md-10 pt-3" v-if="!loading">
                    <div class="time">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z" /></svg>
                        <span>Duration: {{lesson.duration}}</span>
                    </div>
                    <div class="py-3">
                        <h1 class="text-uppercase bold-text custom">
                            {{lesson.name}}
                        </h1>
                        <p class="full-width">
                            {{lesson.description}}
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-9 col-xl-9" id="videos">
                    <div class="media-wrapper pb-4" v-for="(content, i) in lessonContent" :key="content.id">
                        <div class="onb-embed-responsive onb-embed-responsive__16by9" v-if="content.desktopMediaAsset.mimeType == 'video/mp4'">
                            <video class="onb-embed-responsive-item" ref="video"
                                   :id="content.id"
                                   width="640"
                                   height="360"
                                   controls="controls"
                                   preload="auto"
                                   :video-id="getVideoObject(content.id)"
                                   :video-duration.prop="durationTime"
                                   :current-time.prop="currentTime"
                                   @play.once="startedPlaying"
                                   @pause="pausedPlaying"
                                   @timeupdate="onTimeUpdateListener"
                                   @ended="endedPlaying">
                                <source :src="content.desktopMediaAsset.cdnUri+'#t='+ startTime" :type="content.desktopMediaAsset.mimeType">
                            </video>
                        </div>
                        <div v-if="content.desktopMediaAsset.mimeType == 'application/pdf'">
                            <div ref="pdf" class="hasPDF">
                                <vue-pdf-app theme="light" style="height: 100vh;" :pdf="content.desktopMediaAsset.cdnUri" :page-number="1" :config="config"></vue-pdf-app>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 pb-3 buttons text-center" v-if="!loading">
                    <div class="mb-3 mx-auto">
                        <button class="btn btn-primary dark rounded-0 border-0 px-3 py-2" :disabled="loadingPopup" @click.stop="goToLesson()">
                            <span v-show="loadingPopup" class="spinner-border spinner-border-sm"></span>
                            <span>Login / Register</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <GDialog v-model="dialogState">
        <div class="form-wrapper">
            <div class="container">
                <Form class="no-lr-padding" @submit="handleLogin()" :validation-schema="schema" v-slot="{ errors }">
                    <div class="details">
                        <button class="close"
                                @click.prevent="dialogState = false">
                            x
                        </button>
                        <h2>REGISTER OR LOGIN TO CONTINUE. <br />IT'S FREE!</h2>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <Field type="email" v-model="model.userName" id="name" name="username" class="form-control rounded-0" :class="{ 'is-invalid': errors.username }" />
                                    <label for="name">Email Address *</label>
                                    <div class="invalid-feedback">{{errors.username}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <Field type="password" v-model="model.password" id="password" name="password" class="form-control rounded-0" :class="{ 'is-invalid': errors.password }" />
                                    <label for="name">Password *</label>
                                    <div class="invalid-feedback">{{errors.password}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <div class="dmessage">
                            <div class="redColour" v-if="dmessage">
                                {{ dmessage }}
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-primary rounded-0 px-4" :disabled="loginloading" @click="goToLesson()">
                                <span v-show="loginloading" class="spinner-border spinner-border-sm"></span>
                                <span>Login</span>
                            </button>
                        </div>
                        <div class="divider"><span>OR</span></div>
                        <div><a href="/register" class="btn btn-outline-primary rounded-0">Register</a></div>
                        <div><a href="/forgot-password" class="text-medium">Forgot password?</a></div>
                    </div>
                </Form>
            </div>
        </div>
    </GDialog>
</template>
<script lang="ts">
    import { defineComponent, ref } from 'vue';
    import axios from 'axios';
    import apiUrl from '../scripts/BaseUrl';

    import { GDialog } from 'gitart-vue-dialog'
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';

    import VuePdfApp from "vue3-pdf-app";
    import "vue3-pdf-app/dist/icons/main.css";

    const lessonId = location.pathname.split('/')[2]

    export default defineComponent({
        name: 'Lesson',
        components: {
            VuePdfApp,     
            GDialog,
            Form,
            Field,
        },
        data() {
            const schema = yup.object().shape({
                username: yup.string().required('Email is required').email('Email is invalid'),
                password: yup.string().required("Password is required!"),
            });
            return {
                loading: false,
                loadingAllLessons: false,
                nextLoading: false,
                videoEnded: false,
                buttonSignin: false,
                course: '',
                theme: '',    
                lesson: {} as any,
                module: '',
                lessonContent: [] as any,
                moduleLessons: [] as any,
                currentIndex: 0,
                config: {
                    toolbar: {
                        toolbarViewerRight: {
                            presentationMode: false,
                            openFile: false,
                            viewBookmark: false,
                        }
                    },
                },
                videoData: [] as any,
                startTime: 10,
                durationTime_: 0,
                currentTime_: 0,
                getVideoId: '',
                getTimeUpdate: '',
                navInfo: '',                
                allCourses: [] as any,
                courseObject: {
                    courseName: '',
                    courseId: '',
                    themeName: '',
                    themeId: '',
                    moduleName: '',
                    moduleId: '',
                    lessonName: '',
                    lessonId: '',
                },
                dialogState: false,
                loadingPopup: false,
                dmessage: '',
                model: {
                    userName: '',
                    password: '',
                },
                message: '',
            }
        },
        setup() {
            const video = ref();
            return {
                video
            }
        },
        methods: {   
            download(pdfUrl: any) {  
                let text = pdfUrl;
                let filename = 'lesson.pdf';
                let element = document.createElement('a');
                element.setAttribute('href', text);
                element.setAttribute('download', filename);
                element.setAttribute('target', '_blank');
                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            },
            backPage() {            
                history.back()
            },
            goToLesson() {  
                if (localStorage.getItem("user")) {
                    //window.sessionStorage.setItem("courseObjectSchool", JSON.stringify(courseObjectSchool));
                    window.location.href = '/school#/lesson/' + lessonId
                } else {
                    this.dialogState = true;

                }
            },
            handleLogin() {
                this.loading = true;
                const authData = {
                    auth:
                    {
                        username: this.model.userName,
                        password: this.model.password
                    }
                }
                const authToken = window.btoa(this.model.userName + ':' + this.model.password);
                return axios.post(apiUrl + '/accounts', this.model, authData)
                    .then((response) => {
                        let user = response.data;
                        user.token = authToken;
                        localStorage.setItem('user', JSON.stringify(user));
                        if (user.deactivatedAt) {
                            this.dialogState = true;
                            this.dmessage = 'User have been Deactivated';
                        } else {
                            this.dialogState = false;
                            this.loading = true;
                            window.location.href = '/school#/lesson/' + lessonId;
                        }

                    }, (error) => {
                        this.loading = false;
                        this.dialogState = true;
                        if (error.response) {
                            if (error.response.data) {
                                this.dmessage = error.response.data;
                            } else {
                                this.dmessage = 'An error occured. contact your system administrator';
                            }

                        } else {
                            this.message = error.response.data.message;
                        }
                    });
            },
            getVideoObject(id: any) {
                const videoData = JSON.parse(localStorage.getItem('videos') || '[]');
                let video = videoData.find((video: { videoId: string }) => video.videoId === id);

                if (video) {
                    if (video.timeUpdate === video.durationTime) {
                        videoData.splice(video, 1);
                        localStorage.setItem('videos', JSON.stringify(videoData));
                    }

                    this.startTime = video.timeUpdate;
                } else {
                    this.startTime = 10;
                }

            },
            startedPlaying(event: any) {
                const videoData = JSON.parse(localStorage.getItem('videos') || '[]');
                let video = videoData.find((video: { videoId: string }) => video.videoId === event.target.id);
                if (video) {
                    this.startTime = video.timeUpdate;
                } else {
                    this.startTime = 0;
                }
            },
            onTimeUpdateListener(event: any) {
                this.currentTime_ = event.target.currentTime;
                this.durationTime_ = event.target.duration;

                let entries = [];
                let videoId = event.target.id;
                let timeUpdate = this.currentTime;
                let durationTime = this.durationTime;

                entries = JSON.parse(localStorage.getItem('videos') || '[]');
                let entry = entries.find((entry: { videoId: string }) => entry.videoId === videoId);
                if (entry) {
                    entry.durationTime = durationTime;
                    entry.timeUpdate = timeUpdate;
                } else {
                    entries.push({ videoId, timeUpdate, durationTime });
                }
                localStorage.setItem('videos', JSON.stringify(entries));
            },
            pausedPlaying(event: any) {
                return event;
            },
            endedPlaying() {
            },
            checkUserExists() {
                if (localStorage.getItem("user")) {
                    window.location.href = '/school#/lesson/' + lessonId;  
                }
            },
        },
        async mounted() {  
            const requestOne = axios.get(apiUrl + '/lessons/' + lessonId);  
            const requestTwo = axios.get(apiUrl + '/lessons/' + lessonId + '/lesson-content');

            await axios.all([requestOne, requestTwo])
                .then(axios.spread((...responses) => {
                    this.lesson = responses[0].data;   
                    this.lessonContent = responses[1].data.items;
                }))
                .catch((error) => {
                    return error;
                })
                .finally(() => this.loading = false);           
        },
        created() {
            this.getVideoObject(this);
            sessionStorage.setItem('LessonID', lessonId);    
            this.checkUserExists();
        },
        computed: {
            currentTime: {
                get: ({ currentTime_ }: any) => currentTime_,
                set(time: any) {
                    this.video.currentTime = time;
                }
            },
            durationTime: {
                get: ({ durationTime_ }: any) => durationTime_,
                set(duration: any) {
                    this.video.durationTime = duration;
                }
            },            
        }

    })
</script>
